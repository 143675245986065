import ImageUpload from '@/components/BackOffice/ComponentSetters/ImageUpload.vue'
import ImageDetailsService from '@/services/ImageDetailsService.js'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Factory from "@/utils/ContentFactory.js";

export default {
    components:{
        'image-upload': ImageUpload,
        ImageDetailsService
    },
    props:{
        component: Object
    }, 
    data(){
        return{
            editor: ClassicEditor,
            editorConfig: {
                toolbar: [ 'Heading', '|',  'bold', '|', 'italic', '|',  'bulletedList', '|',  'numberedList',  '|']
            }
        }
    },
    computed:{
        isActive(){
            return this.currentComponent == this.component
        }, 
        options(){
            return {name: 'image-details', text:'addImageText', model:'imageId'}
        },
        currentComponent(){
            return this.$store.state.currentBackOfficeComponent
        }, 
        image(){
            return this.component.images[0]
        },
        components(){
            return this.$store.state.assessmentQuestions
        }, 
    }, 
    methods:{
        deleteComponent(){
            
            let removeActionName = 'removeBiographyBuilderComponent'
            let removeMutationName = 'deleteBiographyBuilderComponent'
            let objectToMutate = this.component;

            if(this.$route.name != 'BiographyBuilder' )
            {
                removeActionName ='removeLessonBuilderComponent'
                removeMutationName = 'deleteLessonBuilderComponent'
            }
            if(this.$route.name === 'AssessmentBuilder' )
            {
                removeActionName ='removeQuestionFromAssessment'
                removeMutationName = "updateQuestionComponentOrder";
            }
            this.$store.commit('updateLoadingStatus', true)
            setTimeout(()=>{
            this.$store.dispatch(removeActionName, this.component)
                .then(()=>{
                    if(removeMutationName){
                        if(this.$route.name === "AssessmentBuilder")
                            objectToMutate = this.components;

                        this.$store.commit(removeMutationName, objectToMutate)
                    }
                    this.$store.commit('updateCurrentBackOfficeComponent', undefined)
                    this.$store.commit('updateLoadingStatus', false)
                })
            }, 500)
        },
        updateCurrentComponent(){
            this.$store.commit('updateCurrentBackOfficeComponent', this.component)
        }, 
        setComponentPropertyValue(value, propertyName = this.options.model, language = null){
            this.$store.commit('setBuilderComponentPropertyValue',
                    {'component': this.component, 'propertyName': propertyName, 'value': value, language: language})
        }, 
        newImageDetails(imageId){
            let newImageDetails= {
                imageId: imageId,
                paragraphId: this.component.id,
                position: 'inherit',
                shadow: false,
                originalSize: false,
                audio:{},
                caption: new Factory.CreateTextContent(),
                captionAudio: new Factory.CreateAudio(),
                title: new Factory.CreateTextContent(),
                disclaimer: new Factory.CreateTextContent(),
                captionMargin: 0,
                marginBottom: 0
            }
            ImageDetailsService.linkImageToParagraph(newImageDetails)
            .then(({data})=>{
                newImageDetails.id = data.id;
                newImageDetails.caption.id = data.caption.id;
                newImageDetails.title.id = data.title.id;
                newImageDetails.disclaimer.id = data.disclaimer.id;
                this.$store.commit('addImageToComponent',
                {component :this.component, image: newImageDetails })
            }, () => {
                this.$store.commit('addSnackBarNotification', {
                            message: this.$t("failedToUploadImage"),
                            dismissable: false
                        });
            });
        }, 
        removeImageDetails(imageId){
            let imageDetails = this.component.images.find(e => e.imageId == imageId)
            ImageDetailsService.removeImageFromParagraph(imageDetails.id)
                .then(()=>{
                    this.$store.commit('removeImageFromComponent',
                        {component :this.component, imageId: imageDetails.id });
                })
        }
    }, 
}